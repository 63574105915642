import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Toptitle from "../components/toptitle"

const News = () => (
  <Layout>
    <SEO title="お知らせ" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <Toptitle>News</Toptitle>
        <div className="pt-32 lg:mt-16 lg:pt-0">
          <h1 className="font-mincho text-3xl text-primary">お知らせ</h1>
        </div>
        <div className="m-auto mt-8 w-2/3">
          <article className="my-8">
            <span className="inline-block font-mincho text-primarySupport">
              2022.6.28
            </span>
            <h3 className="font-mincho text-2xl text-primary">
              ブランドロゴを一新しました。
            </h3>
            <p className="py-4 font-gothic">
              新たなロゴとともに今後も一層各サービスを強化してまいります。
              <br />
              引き続きどうぞよろしくお願いいたします。
            </p>
          </article>
          <hr className="my-8 mx-auto w-1/2"></hr>
          <article>
            <span className="inline-block font-mincho text-primarySupport">
              2022.5.2
            </span>
            <h3 className="font-mincho text-2xl text-primary">
              ホームページをリニューアルしました。
            </h3>
            <p className="py-4 font-gothic">
              ホームページをリニューアルしました。
              <br />
              ご不明点やご相談などございましたら、いつでもお気軽にお問い合わせください。
            </p>
          </article>
          <hr className="my-8 mx-auto w-1/2"></hr>
        </div>
      </section>
    </div>
  </Layout>
)

export default News
